import * as React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => {
  return (
    <div className="container">
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <h1>404</h1>
        <h2>OOPS! NOTHING WAS FOUND</h2>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/">Go back to homepage</Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
